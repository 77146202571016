<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import userService from '../../../src/helpers/userService';
import playerjs from "player.js";
import Swal from "sweetalert2";


/**
 * Starter component
 */
export default {
  page: {
    title: "Session",
  },
  props:{
    video:{
        type: Object,
        required: true
    },
    decMinutes:{
        type: Boolean,
        required: true
    }
  },
  head() {
    return {
      title: `${this.title}`,
    };
  },
  async created(){
    if(this.decMinutes){
      await this.$store.dispatch('video/getVideoViews',this.$route.params.video_id).then( async token =>{
          this.remainingMinutes = token.duration_minutes;
          if(token.duration_minutes == 0){
            this.errorCall();
          }
      }).catch( err =>{
        this.errorCall();
      })
    }
  },
  methods:{
    errorCall(){
      Swal.fire({
        title: "You have watched all your minutes !",
        icon: "info",
        showCancelButton: false,
        confirmButtonText: "Ok",
        timer:1500
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push('/students/lectures');
        } else {
          this.$router.push('/students/lectures');
        }
      });
    },
    async initPlayer() {
      if(this.decMinutes){
        const iframe = await document.getElementById('bunny-stream-embed');
        const player = await new playerjs.Player(iframe);
        await player.on('ready', () => {
        player.on('play', () => {
            this.startTimer();
            console.log('play');
        });
        player.on('pause', () => {
            this.stopTimer();
            console.log('pause');
        });

        });
      }
    },
    async startTimer() {
      this.startTime = await new Date().getTime();
      this.playState = true;
      this.intervalId = await setInterval(() => {
        if(this.playState){
          const currentTime = new Date().getTime();
          this.totalWatchedTime += (currentTime - this.startTime) / 60000;
          this.startTime = currentTime;
          let deffrance = Math.floor(this.totalWatchedTime) - this.watchedMinutes;
          if( deffrance >= 1 && this.cache[this.remainingMinutes] != this.remainingMinutes){
            // call the dec function 
            this.cache[this.remainingMinutes] = this.remainingMinutes;
            this.$store.dispatch('video/decMinutes',{video_id:this.video.video_id,minutes:deffrance,stud_id:userService.methods.userID()}).then(async ()=>{
              await this.$store.dispatch('video/getVideoViews',this.$route.params.video_id).then( async token =>{
                  this.remainingMinutes = token.duration_minutes;
                  if(token.duration_minutes == 0){
                    this.errorCall();
                  }
              }).catch( err =>{
                this.errorCall();
              })
            }).catch( err =>{
              this.errorCall();
            })
          }
          this.watchedMinutes = Math.floor(this.totalWatchedTime);
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.intervalId);
      this.playState = false;
    }
  },
  data() {
    return {
      playState: true,
      startTime: null,
      totalWatchedTime: 0,
      intervalId: null,
      watchedMinutes: 0,
      remainingMinutes: 0,
      cache: {},
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-lg-9">
            <div class="card">
                <div class="card-body">
                    <p class="sub-header font-18 text-center" v-if="video.educational_year && video.lecture">
                    {{ video.educational_year.number + ' - ' + video.educational_year.description }} /
                    <code>{{ video.lecture.name }}</code>
                    </p>
                    <h2 class="mb-2 text-center">{{ video.name }}</h2>

                    <!-- 16:9 aspect ratio -->
                    <div class="embed-responsive embed-responsive-16by9 mb-2">
                      <!-- <div style="position: relative; padding-top: 56.25%;"> -->
                        <iframe @load="initPlayer" v-if="this.video.video_id && this.video.library_id" ref="iframe" id="bunny-stream-embed" :src="`https://iframe.mediadelivery.net/embed/${this.video.library_id}/${this.video.video_id}?autoplay=false`" 
                            loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" 
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
                        </iframe>
                      <!-- </div> -->
                    </div>
                    <div class="text-center justify-content-center mt-1 mb-3" v-if="this.watchedMinutes >= 0 && this.decMinutes">
                      <div class="auth-logo">
                          <h1 class="logo-lg bg-danger " style="width: 70%; margin: auto auto;border-radius: 5px;">
                            Watched Minutes: {{ watchedMinutes }}
                          </h1>
                      </div>
                    </div>
                    <div class="text-center justify-content-center mb-3" v-if="this.remainingMinutes >= 0 && this.decMinutes">
                      <div class="auth-logo">
                          <h1 class="logo-lg bg-success " style="width: 70%; margin: auto auto;border-radius: 5px;">
                            Remaining Minutes: {{ remainingMinutes }}
                          </h1>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
