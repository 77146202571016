<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import userService from '../../../helpers/userService';
import playerjs from "player.js";
import PlayerComponent from '@/components/Videos/Player';


/**
 * Starter component
 */
export default {
  page: {
    title: "Session",
  },
  head() {
    return {
      title: `${this.title}`,
    };
  },
  async created(){
    await this.$store.dispatch('video/getVideoInfo',this.$route.params.video_id).then( async token =>{
      this.video.name = token.video_name;
      let pattern = /^[vV][iI][dD].*/;
      if(await pattern.test(this.video.name)){
        this.decMinutes = false;
      }else{
        this.decMinutes = true;
      }
      this.video.lecture = token.chapter;
      this.video.educational_year = token.chapter.educational_year;
      this.video.video_id = token.video_id;
      this.video.library_id = token.library_id;
      this.video.id = this.$route.params.video_id;
    }).catch( err =>{
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
    })
  },
  data() {
    return {
      decMinutes: null,
      video:{
        id: null,
        name: null,
        educational_year: null,
        lecture: null,
        video_id: null,
        library_id: null,
      },
      title: "Videos",
      items: [
        {
          text: "Manage",
          href: "/",
        },
        {
          text: "Videos",
          href: "/videos/my-sessions",
        },
        {
          text: "View",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    PlayerComponent,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PlayerComponent :video="video" :decMinutes="decMinutes" v-if="video && decMinutes != null"/>
  </Layout>
</template>
